import React, { useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
import { navigate } from 'gatsby'

const RECAPTCHA_KEY = '6Lfe7Q4eAAAAAHWz69RgDegcIbBKUbNZhm4aPOJo'

export default function ContactForm() {

    const [state, setState] = useState({})

    // The button is disabled by default, but on Recaptcha change, the form can be submitted
    const [buttonDisabled, setButtonDisabled] = React.useState(true)

    const recaptchaRef = React.createRef() // new Ref for reCaptcha

	const encode = data => {
		return Object.keys(data)
		  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		  .join("&")
	}

	// Save state of each input on change
    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

	const handleSubmit = e => {
		e.preventDefault()
		const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()

        // Validate inputs
        if (form.checkValidity() === false) {
            form.classList.add('was-validated')
            return
        }

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
                'g-recaptcha-response': recaptchaValue,
                'subject': "RippleGo Contact Form Submission",  // Custom subject line in email
				...state,
			}),
		})
		  .then(() => navigate(form.getAttribute('action')))
		  .catch(error => alert(error))
	}

    return (
        <form noValidate className="needs-validation" id="contactForm" name="contactForm" method="POST" onSubmit={handleSubmit}
            netlify-honeypot="bot-field" data-netlify="true" data-netlify-recaptcha="true"
            action="/thanks/">
            <input type="hidden" name="form-name" value="contactForm" />
            <div className="form-group">
                <h6 className="contact-label">Full Name <span className="text-danger">*</span></h6>
                <input onChange={handleChange} className="form-control" type="text" name="name" required/>
                <div className="invalid-feedback">
                    Please enter your full name.
                </div>
            </div>
            <div className="form-group">
                <h6 className="contact-label">Email <span className="text-danger">*</span></h6>
                <input onChange={handleChange} className="form-control" type="email" aria-describedby="emailHelp" name="email" required />
                <div className="invalid-feedback">
                    Please enter a valid email.
                </div>
            </div>
            <div className="form-group">
                <h6 className="contact-label">Company Name <span className="text-danger">*</span></h6>
                <input onChange={handleChange} className="form-control" type="company" aria-describedby="companyHelp" name="company" required />
                <div className="invalid-feedback">
                    Please enter a company name.
                </div>
            </div>
            <div className="form-group">
                <h6 className="contact-label">How can we help you? <span className="text-danger">*</span></h6>
                <select defaultValue="" onBlur={handleChange} className="form-select" name="request" required>
                    <option disabled value="">-- Select an option --</option>
                    <option value="I want more information">I want more information about RippleGo</option>
                    <option value="I want to become a RippleGo Beta Tester">I want to become a RippleGo Beta Tester</option>
                    <option value="I want to request a RippleGo demo">I want to request a RippleGo demo</option>
                    <option value="Other">Other</option>
                </select>
                <div className="invalid-feedback">
                    Please select an option.
                </div>
            </div>
            <div className="form-group">
                <h6 className="contact-label">Your Message <span className="text-danger">*</span></h6>
                <textarea onChange={handleChange} name="message" className="form-control" rows="5" required></textarea>
                <div className="invalid-feedback">
                    Please enter a message.
                </div>
            </div>
            <p hidden>
                <label>
                    <b>Dummy <span className="text-danger">*</span></b>
                    <input name="bot-field"/>
                </label>
            </p>
            <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                id="recaptcha-google"
                onChange={() => setButtonDisabled(false)}
            />
            <button disabled={buttonDisabled} className="link-button submit mt-3" type="submit">Submit</button>
        </form>
    )

}

